<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Уважаемые партнеры!</h1>
          <h2>Выражаем благодарность за&nbsp;сотрудничество с&nbsp;<b>ПАО&nbsp;СК&nbsp;&laquo;РОСГОССТРАХ&raquo;</b> и&nbsp;поздравляем с&nbsp;победой в&nbsp;конкурсной программе!</h2>
        </div>
      </div>
      <hr>

      <p>Для выбора подарка нажмите соответствующую кнопку.</p>

      <div class="buttons">
        <router-link class="button button--transparent" :to="{ name: 'CabinetMyGift' }"><span>Пластиковые карты MyGift</span></router-link>
        <router-link class="button button--transparent" :to="{ name: 'CabinetEGC' }"><span>Электронные Подарочные Сертификаты</span></router-link>
      </div>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 49%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 49%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }
</style>
