<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Уважаемые партнеры!</h1>
          <h2>Мы&nbsp;рады приветствовать Вас на&nbsp;нашем сайте программы мотивации для сотрудников <nobr>Банков-партнеров</nobr> <b>ПАО&nbsp;СК&nbsp;&laquo;РОСГОССТРАХ&raquo;</b></h2>
        </div>
      </div>
      <hr>

      <p>С&nbsp;помощью мотивационной программы мы&nbsp;хотим выразить благодарность за&nbsp;активное сотрудничество!</p>
      <h3>Вознаграждение</h3>
      <p>Вознаграждением является предоплаченная Банковская карта VISA! Оплачивайте ей&nbsp;любые покупки в&nbsp;торговых сетях по&nbsp;РФ&nbsp;и&nbsp;в&nbsp;сети Интернет на&nbsp;российских сайтах.*</p>
      <p>На&nbsp;балансе в&nbsp;Вашем Личном кабинете будем сумма, равная сумме приза за&nbsp;конкурс.</p>
      <p>Максимальная сумма предоплаченной карты 15&nbsp;000&nbsp;рублей. Вы&nbsp;получите подарочные карты, сумма которых будет равна сумме Вашего приза за&nbsp;конкурс.</p>
      <p>При получении Приза Вам необходимо будет заполнить Анкету и&nbsp;приложить документы для декларации дохода и&nbsp;уплате подоходного налога. Налог уплачивается за&nbsp;счет организатора конкурса&nbsp;&mdash; ПАО СК&nbsp;&laquo;Росгосстрах&raquo; (Вам не&nbsp;придется подавать декларацию о&nbsp;доходе самостоятельно). После проверки документов, необходимых для уплаты подоходного налога, и&nbsp;подписания Акт <nobr>приема-передачи</nobr> подарка, предоплаченная Банковская карта VISA будет направлена Вам на&nbsp;указанный адрес в&nbsp;течение 1&ndash;2 рабочих дней.</p>
      <p>Активируйте карту на&nbsp;сайте <a href="http://mygift.ru/" target="_blank">http://mygift.ru/</a> и&nbsp;приступайте к&nbsp;приятным покупкам!</p>
      <p>Вы&nbsp;можете расплачиваться картой в&nbsp;любом месте, где принимают карты VISА: магазинах, ресторанах, салонах красоты, супермаркетах, аптеках, автозаправках на&nbsp;территории России. Карта также подходит для любых <nobr>интернет-платежей</nobr> на&nbsp;российских сайтах.</p>
      <p>Повторное пополнение и&nbsp;снятие наличных с&nbsp;карты не&nbsp;предусмотрено.</p>
      <p>Технический <nobr>PIN-код</nobr> к&nbsp;карте&nbsp;&mdash; 0000 (запомните его, он&nbsp;может понадобиться при оплате в&nbsp;магазине).</p>
      <p>Важно! Срок действия вашей карты может отличаться от&nbsp;указанного на&nbsp;карте. Проверить срок можно <a href="https://mygift.gift-cards.ru/features" target="_blank">здесь</a>.</p>
      <p class="cards-term">Срок пополнения пластиковых карт <b>до 3-х рабочих дней с момента предоставления и подтверждения всех необходимых документов.</b></p>
      <p><a href="http://mygift.ru/Cards/classic/Faq" target="_blank"><nobr>Вопрос-Ответ</nobr></a></p>

      <hr>
      <p><b>Мы&nbsp;всегда рады ответить на&nbsp;Ваши вопросы:</b></p>
      <p><b>По&nbsp;вопросам начисления суммы призов:</b><br>
      Банк Открытие: <a href="mailto:Angelina_Soboleva@rgs.ru">Angelina_Soboleva@rgs.ru</a><br>
      ОТП Банк: <a href="mailto:Ekaterina_Ivanova@rgs.ru">Ekaterina_Ivanova@rgs.ru</a></p>
      <p><b>По&nbsp;вопросам активации подарочных карт и&nbsp;заполнению документов:</b><br>
      Email: <a href="mailto:support@giftery.ru">support@giftery.ru</a><br>
      Горячая линия: <a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a> (<nobr>пн-пт</nobr> с&nbsp;9 до&nbsp;18)</p>
      <br>
      <router-link class="button button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
      <router-link :to="{ name: 'Cabinet' }">&larr; Вернуться к выбору</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<style>
.cards-term {
  color: red;
}
</style>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>
