<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Уважаемые партнеры!</h1>
          <h2>Мы&nbsp;рады приветствовать Вас на&nbsp;нашем сайте программы мотивации для сотрудников партнеров <b>ПАО&nbsp;СК&nbsp;&laquo;РОСГОССТРАХ&raquo;</b></h2>
        </div>
      </div>
      <hr>

      <p>С&nbsp;помощью мотивационной программы мы&nbsp;хотим выразить благодарность за&nbsp;активное сотрудничество!</p>
      <h3>Вознаграждение</h3>
      <p>Вознаграждением являются Электронные Подарочные Сертификаты различных брендов! На&nbsp;балансе в&nbsp;Вашем Личном кабинете будем сумма, равная сумме приза за&nbsp;конкурс.</p>
      <p>Вы&nbsp;можете выбирать Сертификаты возможных номиналов на&nbsp;часть суммы приза или на&nbsp;всю сумму целиком. Сумма Вашего приза не&nbsp;сгорает в&nbsp;Личном кабинете, можно будет выбрать сертификаты в&nbsp;любое удобное время.</p>
      <p>При достижении суммы приза более 4000&nbsp;рублей Вам необходимо будет заполнить Анкету и&nbsp;приложить документы для декларации дохода и&nbsp;уплате подоходного налога. Налог уплачивается за&nbsp;счет организатора конкурса&nbsp;&mdash; ПАО СК&nbsp;&laquo;Росгосстрах&raquo; (Вам не&nbsp;придется подавать декларацию о&nbsp;доходе самостоятельно). После проверки документов, необходимых для уплаты подоходного налога, и&nbsp;подписания Акт <nobr>приема-передачи</nobr> подарка, Подарочные Сертификаты будут направлены Вам на&nbsp;указанный <nobr>e-mail</nobr> в&nbsp;течение 1&ndash;2 рабочих дней.</p>
      <hr>

      <p><b>Мы&nbsp;всегда рады ответить на&nbsp;Ваши вопросы:</b></p>
      <p><b>По&nbsp;вопросам начисления суммы призов:</b><br>
      Банк Открытие: <a href="mailto:Angelina_Soboleva@rgs.ru">Angelina_Soboleva@rgs.ru</a><br>
      ОТП Банк: <a href="mailto:Ekaterina_Ivanova@rgs.ru">Ekaterina_Ivanova@rgs.ru</a><br>
      Автосалоны (Дилеры): Куратор автодилера</p>
      <p><b>По&nbsp;вопросам подарочных сертификатов и&nbsp;заполнению документов:</b><br>
      Email: <a href="mailto:support@giftery.ru">support@giftery.ru</a><br>
      Горячая линия: <a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a> (<nobr>пн-пт</nobr> с&nbsp;9 до&nbsp;18)</p>
      <br>
      <router-link class="button button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
      <router-link :to="{ name: 'Cabinet' }">&larr; Вернуться к выбору</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>
